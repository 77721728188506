<template>
  <ion-app>
    <ion-header v-if="!_.includes(route.path, 'admin')" class="main-header">
      <div
        :class="headerColor ? 'bg-base-black-main/50' : 'bg-base-black-main'"
        class="h-[60px] w-full flex items-center justify-center">
        <div class="px-[16px] flex items-center w-full max-w-[768px] h-full justify-between">
          <logo class="flex-none cursor-pointer" fill="#fff" width="95" @click="goPage('home')" />
          <div class="ml-[24px] w-full h-full md:flex items-center gap-x-[12px] text-[14px] hidden">
            <div
              :class="
                route.path === '/announce' ? 'font-bold text-white' : headerColor ? 'text-white' : 'text-white/60'
              "
              class="cursor-pointer"
              @click="goPage('announce')">
              공지사항
            </div>
            <div
              :class="route.path === '/QnA' ? 'font-bold text-white' : headerColor ? 'text-white' : 'text-white/60'"
              class="cursor-pointer"
              @click="goPage('QnA')">
              자주 묻는 질문
            </div>
            <div
              :class="
                route.path === '/directions' ? 'font-bold text-white' : headerColor ? 'text-white' : 'text-white/60'
              "
              class="cursor-pointer"
              @click="goPage('directions')">
              오시는 길
            </div>
          </div>
          <div class="flex items-center justify-end">
            <div
              class="flex-none bg-white rounded-full px-[12px] h-[24px] mx-[12px] min-w-fit flex items-center gap-x-[6px] cursor-pointer"
              @click="goWeather">
              <weather_watch />
              <div class="text-base-black-main text-[12px] font-bold">날씨 확인하기</div>
            </div>
            <ion-menu-toggle>
              <menuIcon class="md:hidden" width="32" />
            </ion-menu-toggle>
            <div
              v-show="!isLogin"
              class="flex-none md:flex items-center gap-x-[6px] hidden cursor-pointer"
              @click="goPage('login')">
              <userIcon />
              <div class="text-[14px] text-white font-bold">로그인</div>
            </div>
            <div
              v-show="isLogin"
              id="login-button"
              class="flex-none md:flex items-center gap-x-[6px] hidden cursor-pointer">
              <div class="text-[16px] text-white font-bold leading-[16px]">{{ authStore.user.login_id }}</div>
              <arrowDown16 />
            </div>
          </div>
          <ion-popover
            v-if="isLogin"
            ref="popoverRef"
            :arrow="false"
            :showBackdrop="true"
            alignment="end"
            trigger="login-button">
            <ion-header>
              <div class="px-[12px] bg-white">
                <div
                  class="text-base-black-600 text-[12px] w-full h-[34px] flex items-center border-b-[1px] border-base-default">
                  최근접속 : {{ authStore.user.login_date }}
                </div>
              </div>
            </ion-header>
            <ion-content>
              <userInfoComponent v-if="isLogin" class="select-none" @action-fn="goPage" />
              <div class="p-[12px] w-full h-full flex flex-col justify-end">
                <div
                  class="text-[10px] text-base-black-400 text-right leading-[100%] mb-[8px] cursor-pointer"
                  @click="goPage('deleteUser')">
                  회원탈퇴하기
                </div>
                <div
                  class="flex items-center justify-center w-full h-[34px] border-[1px] border-base-black-main bg-white text-[12px] text-base-black-main rounded-[4px] cursor-pointer"
                  @click="actionLogout">
                  로그아웃
                </div>
              </div>
            </ion-content>
          </ion-popover>
        </div>
      </div>
    </ion-header>
    <ion-router-outlet id="main-content" :animated="false"></ion-router-outlet>
    <ion-footer v-if="!_.includes(route.path, 'admin')">
      <div class="bg-base-black-main w-full md:flex items-center justify-center hidden">
        <div class="p-[16px] flex justify-between w-full max-w-[768px] h-full">
          <div class="">
            <logo class="" fill="#fff" width="95" />
            <img v-if="dayjs().valueOf() <= dayjs('2024-12-31').valueOf()" :src="receipt_2024" class="pt-[7px]" width="95"/>
            <img v-else :src="receipt_2025" class="pt-[7px]" width="95"/>
          </div>

          <div class="flex flex-col">
            <div class="flex items-center gap-x-[12px]">
              <div class="text-[12px] text-white font-bold cursor-pointer" @click="goPage('privacy')">
                개인정보처리방침
              </div>
              <div class="text-[12px] text-white">|</div>
              <div class="text-[12px] text-white font-bold cursor-pointer" @click="goPage('terms')">이용약관</div>
            </div>
            <div class="mt-[10px] text-[10px] text-white whitespace-pre-line">
              {{
                "주식회사 진인 \n서울시 용산구 한강대로 23길 55 아이파크몰 리빙파크 9층 \n대표이사 : 최창덕 \n사업자번호 : 106-85-38868 \n통신판매업 신고 : 제2021-서울용산-1074"
              }}
            </div>
          </div>
          <div class="flex flex-col items-end justify-center text-white">
            <div class="text-[12px] font-bold">고객센터</div>
            <div class="text-[18px] font-bold">02-2012-3810</div>
            <div class="text-[10px]">(평일 09:00 ~ 18:00)</div>
          </div>
        </div>
      </div>
      <div class="md:flex justify-center w-full text-[10px] text-white bg-black hidden">
        COPYRIGHT(C) JININ CO.LTD. ALL RIGHTS RESERVED. | Powered by
        <span class="cursor-pointer pl-[2px]" @click="goAlpha"> ALPHA ASIA</span>
      </div>
    </ion-footer>
    <ion-menu v-if="!_.includes(route.path, 'admin')" :max-edge-start="0" content-id="main-content" side="end">
      <ion-header>
        <div class="px-[16px] bg-white">
          <userIdComponent :is-login="isLogin" class="h-[60px] select-none" @login-fn="goPage('login')" />
        </div>
      </ion-header>
      <ion-content class="">
        <div class="flex flex-col w-full h-full">
          <div class="flex flex-col divide-y-[10px] divide-base-default">
            <userInfoComponent v-if="isLogin" class="select-none" @action-fn="goPage" />
            <announceComponent class="select-none" @action-fn="goPage" />
          </div>
          <div v-if="isLogin" class="px-[16px] w-full h-full flex flex-col justify-end pb-[16px]">
            <div
              class="text-[12px] text-base-black-400 text-right leading-[10px] mb-[8px] cursor-pointer"
              @click="goPage('deleteUser')">
              회원탈퇴하기
            </div>
            <button
              class="flex items-center justify-center w-full h-[34px] border-solid border-[1px] border-base-black-main bg-white text-[12px] text-base-black-main rounded-[4px] cursor-pointer"
              @click="actionLogout">
              로그아웃
            </button>
          </div>
        </div>
      </ion-content>
    </ion-menu>
    <ion-modal
      id="base_popup"
      ref="modal"
      :backdrop-dismiss="false"
      :can-dismiss="true"
      :handle="false"
      :is-open="isPopup"
      :swipe-to-close="false">
      <div class="bg-white flex flex-col items-center h-full w-full bottom-0 overflow-y-scroll scrollbar-hide">
        <div class="grow w-full flex flex-col items-center relative">
          <swiper
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false
            }"
            :modules="modules"
            :slidesPerView="1"
            class="w-full !h-auto bg-base-black-main aspect-w-2 aspect-h-3 base_popup_swipe"
            loop
            @swiper="setSwiperRef"
            @slide-change="onSlideChange">
            <swiper-slide v-for="(item, index) in popupList as any" :key="index" class="!items-end">
              <div
                :style="`background-image: url('${item.image_url}');`"
                class="bg-cover bg-center bg-no-repeat w-full aspect-w-2 aspect-h-3"
                @click="item.click_url ? actionPreview(item) : ''"></div>
            </swiper-slide>
          </swiper>
          <div v-if="_.size(popupList) > 1" class="absolute bottom-[8px] right-1 flex justify-end px-[10px] z-[1]">
            <div
              class="flex items-center bg-black/60 text-[14px] text-white font-bold px-[6px] py-[2px] rounded-[15px] gap-x-[4px]">
              <div class="">{{ nowIndex }}</div>
              <div class="opacity-[60] whitespace-pre-wrap">| {{ _.size(popupList) }}</div>
            </div>
          </div>
        </div>
        <div class="w-full grid grid-cols-2 gap-x-[10px] p-[12px]">
          <button
            class="bg-white rounded-[8px] w-full h-[48px] flex items-center justify-center text-[14px] text-base-black-main cursor-pointer"
            @click="setCloseToday">
            {{ "오늘 하루 열지 않기" }}
          </button>
          <button
            class="bg-base-black-main rounded-[8px] w-full h-[48px] flex items-center justify-center text-[14px] text-white font-bold cursor-pointer"
            @click="setOpen(false)">
            {{ "확인" }}
          </button>
        </div>
      </div>
    </ion-modal>
  </ion-app>
  <LoadinfComponent v-show="commonStore.isLoading" />
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import dayjs from "dayjs";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import _ from "lodash";

import logo from "@/assets/svg/logo.svg?component";
import menuIcon from "@/assets/svg/menuIcon.svg?component";
import arrowDown16 from "@/assets/svg/arrow_down_16.svg?component";
import userIcon from "@/assets/svg/userIcon_16.svg?component";
import weather_watch from "@/assets/svg/weather_watch_16.svg?component";
import receipt_2024 from '@/assets/2024_receipt.png';
import receipt_2025 from '@/assets/2025_receipt.png';

import { useAuthStore } from "@/stores/auth";
import { computed, nextTick } from "vue";
import { useCommonStore } from "@/stores/common";

const popupList = ref([]);

const authStore = useAuthStore();
const router = useIonRouter();
const route = useRoute();

const commonStore = useCommonStore();

const popoverRef = ref();
// const getWthrWrnInfo = ref("");

const isPopup = ref(false);
const swiperRef = ref();
const modules = [Autoplay];
const nowIndex = ref(1);

const headerColor = computed(() => {
  return route.name === "home";
});
const isLogin = computed(() => {
  return !_.isEmpty(authStore.user);
});

const setOpen = (open: boolean) => {
  if (!_.includes(route.path, "admin")) {
    isPopup.value = open;
  }
};

const setSwiperRef = async (swiper: any) => {
  swiperRef.value = swiper;
};

const onSlideChange = (e: any) => {
  nowIndex.value = e.realIndex + 1;
};

const setCloseToday = () => {
  const requestTime = new Date().getTime();
  localStorage.setItem("popupClose", requestTime.toString());
  setOpen(false);
};

const goPage = async (type: string) => {
  await menuController.close();
  if (!_.isNil(popoverRef.value)) {
    popoverRef.value.$el.dismiss();
  }
  await nextTick(async () => {
    await router.replace({ name: type });
  });
};

const actionLogout = async () => {
  await authStore.logout();
  if (!_.isNil(popoverRef.value)) {
    popoverRef.value.$el.dismiss();
  }
};

const goWeather = () => {
  const url =
    "https://www.weather.go.kr/w/index.do#dong/1117056000/37.5300763645794/126.964824061815/%EC%84%9C%EC%9A%B8%20%EC%9A%A9%EC%82%B0%EA%B5%AC%20%ED%95%9C%EA%B0%95%EB%A1%9C3%EA%B0%80/SCH/%EC%9A%A9%EC%82%B0%EC%97%AD";
  const w = window.open(url, "_blank");
  if (w) {
    w.focus();
  }
};

const goAlpha = () => {
  const url = "https://app.alpha-asia.com";
  const w = window.open(url, "_blank");
  if (w) {
    w.focus();
  }
};
const actionPreview = (data: any) => {
  const url = data?.click_url;
  if (data.type === "page") {
    window.location.href = url;
  } else {
    const w = window.open(url, "_blank");
    if (w) {
      w.focus();
    }
  }
};
onMounted(async () => {
  // getWthrWrnInfo.value = await useCommonStore().getOpenApiData();
  if (!_.includes(route.path, "admin")) {
    popupList.value = await commonStore.getPopupBannerList({ master_type: "popup", is_range: true });
    if (_.size(popupList.value) > 0) {
      const popupFlag = parseInt(localStorage.getItem("popupClose") as string);
      if (!_.isNaN(popupFlag)) {
        const year = dayjs(popupFlag).add(1, "day").year();
        const month = dayjs(popupFlag).add(1, "day").month();
        const day = dayjs(popupFlag).add(1, "day").date();
        if (
          dayjs().isSame(dayjs(`${year}-${month + 1}-${day}`)) ||
          dayjs().isAfter(dayjs(`${year}-${month + 1}-${day}`))
        ) {
          localStorage.removeItem("popupClose");
          setOpen(true);
        } else {
          setOpen(false);
        }
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }
});
</script>

<style scoped>
ion-popover::part(content) {
  margin-top: 22px;
  border-radius: 0 0 8px 8px;
  border: 0 !important;
}

ion-modal#base_popup {
  --min-width: fit-content;
  --width: 100%;
  --max-width: 400px;
  --border-radius: 12px;
  --min-height: fit-content;
  --height: auto;
  --max-height: 673px;
  --ion-background-color: #fff !important;
  --box-shadow: 0px 0px 30px 0px rgba(47, 47, 47, 0.6);
  padding: 0 20px;
}
.base_popup_swipe {
  .swiper-pagination {
    position: relative !important;
  }
}
</style>
